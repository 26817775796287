import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { egyptLakeReleaseDate, redEarthCreekReleaseDate, hohnAlbumDate } from './releaseDate-data';

export default [
    {
        title: `Höhn`,
        format: `LP`,
        artist: `Höhn`,
        featuredArtist: null,
        // releaseDate: `2022-11-18T00:00:00`,
        date: hohnAlbumDate,
        streamingLinks: [
            {
                name: 'Spotify',
                link: `http://stns.ffm.to/HOHN`,
            },
            {
                name: 'Apple Music',
                link: `http://stns.ffm.to/HOHN`
            },
            {
                name: 'Beatport',
                link: `http://stns.ffm.to/HOHN`
            },
            {
                name: 'SoundCloud',
                link: `http://stns.ffm.to/HOHN`
            },
            {
                name: 'YouTube',
                link: `http://stns.ffm.to/HOHN`
            }
        ],
        coverArt: <StaticImage
        src="../images/music/hohn_album_cover_art_web.jpg"
        quality={75}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hohn album cover"
        className="shadow-lg"
      />
    },
    {
        title: `Egypt Lake`,
        format: `Single`,
        artist: `Höhn`,
        featuredArtist: null,
        // releaseDate: `2022-10-07T00:00:00`,
        date: egyptLakeReleaseDate,
        streamingLinks: [
            {
                name: 'Spotify',
                link: `https://open.spotify.com/track/13PpXsAzoHpUGqurO3cYHf`,
            },
            {
                name: 'Apple Music',
                link: `https://music.apple.com/ca/album/egypt-lake/1659499416?i=1659499417`
            },
            {
                name: 'Beatport',
                link: `https://www.beatport.com/track/egypt-lake/17213271`
            },
            {
                name: 'YouTube',
                link: `https://www.youtube.com/watch?v=mCSP5J52AMY`
            }
        ],
        coverArt: <StaticImage
        src="../images/music/hohn_single_2_cover_art_web.jpg"
        quality={75}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hohn album cover"
        className="shadow-lg"
      />
    },
    {
        title: `Red Earth Creek`,
        format: `Single`,
        artist: `Höhn`,
        featuredArtist: null,
        // releaseDate: `2022-10-28T00:00:00`,
        date: redEarthCreekReleaseDate,
        streamingLinks: [
            {
                name: 'Spotify',
                link: `https://open.spotify.com/track/2I704GCNWbQT8IfWWR0aWa`,
            },
            {
                name: 'Apple Music',
                link: `https://music.apple.com/ca/album/red-earth-creek/1664247333?i=1664247334`
            },
            {
                name: 'Beatport',
                link: `https://www.beatport.com/track/red-earth-creek/17301355`
            },
            {
                name: 'YouTube',
                link: `https://www.youtube.com/watch?v=kRYbdbJI3jc&src=FFM&lid=00000000-63bc-86fe-3300-0013001ee994&cid=eef267f6-3f51-5d46-a717-711ac12bcf76`
            }
        ],
        coverArt: <StaticImage
        src="../images/music/hohn_single_1_cover_art_web.jpg"
        quality={75}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="hohn album cover"
        className="shadow-lg"
      />
    }
]