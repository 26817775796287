import React from 'react';
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout';
import Section from '../components/layout/Section'
// import PageHeader from '../components/PageHeader';
import ReleasesSticky from '../components/ReleasesSticky';

export default function MusicPage() {


    return (
        <Layout>
            <Seo title="Music" description={`Stream Höhn's eponymous LP, wherever you listen to music. Also find links for debut single Egypt Lake and more.`} />
            <Section sectionName={`Music`}>
                <h1 className={`hidden`}>Music</h1>
                <ReleasesSticky />


            </Section>
        </Layout>

    )
}