import React from 'react'
import musicData from '../data/music-data'
// import { Spotify, Apple, Amazon } from '@icons-pack/react-simple-icons';
import Fade from 'react-reveal/Fade';
// import { isTodayOrBefore } from '../utils/isTodayOrBefore';
import { formatMyDate } from '../utils/formatMyDate';

export default function ReleasesSticky(props) {

    const { releaseData } = props;

    let baseData = musicData;
    if (releaseData) baseData = releaseData;

    const getSlug = (name) => {
        return name.replace(/\s+/g, '-').toLowerCase()
    }

    const filteredData = baseData.filter(a => a.date <= Date.now() + (1000 * 60 * 60 * 24 * 7))

    // function isDateBeforeOrToday(date) {
    //     return new Date(date.toDateString()) <= new Date(new Date().toDateString());
    // }

    return (

        <div className={`space-y-10 sm:space-y-0 md:space-y-14 sm:-mx-7 md:mx-auto md:block sm:flex sm:flex-row sm:flex-wrap`}>
            {filteredData.length === 0 ? (
                <h1 className={`text-4xl md:text-5xl md:mt-0 mt-7 font-light font-brand`}>Coming Soon...</h1>
            ) : ( null ) }
            {filteredData
            .sort((a,b) => b.date - a.date)
            .map((song, k) => {

                const songSlug = getSlug(song.title).replace(new RegExp(/[òóôõö]/g),"o"); // get rid of accented o

                const now = new Date()

                const getDate = formatMyDate(new Date(song.date))

                const fullDate = new Date(song.date);

                let releaseLabel = `Coming`;
                if ( fullDate <= now) releaseLabel = null;

                // const fallbackCaption = single1Date.toLocaleString() < now.toLocaleString() ? song.caption : `The debut single from Höhn.`

                return (

                    <Fade bottom duration={500} distance={`70px`} key={k} wait={0} fraction={0.15}>
                        <div className={`sm:flex-1/2 sm:max-w-1/2 sm:px-7 sm:pb-10 md:pb-0 md:px-0 md:grid md:grid-cols-2 lg:grid-cols-12 relative`} id={songSlug}>
                        {song.coverArt &&
                            <div className={`lg:col-span-4`}>{song.coverArt}</div>
                        }

                        {song.title &&
                        
                            <div className={`relative h-full lg:col-span-8`} style={{display: 'initial'}}>
                                <div className={`sticky top-0 block w-full p-7 sm:pt-7 md:p-14 lg:p-20 space-y-5`}>
                                <h2 className={`text-lg md:text-2xl lg:text-3xl font-medium italic lg:inline-flex`}>
                                    {song.title}

                                    {song.featuredArtist && <small className={`leading-[1.8]`}>&nbsp;[feat. {song.featuredArtist}]</small>}
                                </h2>
                                {song.artist &&
                                    <div className={`hidden`}>
                                        {song.artist}
                                    </div>
                                }
                                
                                {
                                    fullDate > now &&

                                    <div className={`text-xs`}>
                                        <strong className={`uppercase font-medium font-sans tracking-widest pr-2 md:pr-5`}>{releaseLabel}: </strong>
                                        <span className={`text-sm`}>{ getDate }</span>
                                    </div>
                                }
                                

                                

                                {(song.streamingLinks.length || song.format) &&
                                
                                    <div className={`block lg:space-y-0 space-y-7 lg:flex lg:flex-row lg:items-start lg:justify-between  leading-tight`}>

                                        {(song.streamingLinks.length && (fullDate <= now)) &&

                                        <div className={`space-y-1 flex flex-col items-start lg:pt-0.5`}>
                                            <div className={`text-xs pb-2`}>
                                                <strong className={`uppercase font-medium font-sans tracking-widest`}>Stream: </strong>
                                            </div>
                                            <div className={`lg:columns-2 lg:gap-x-5 space-y-1`}>
                                            {song.streamingLinks.map((stream, index) => {


                                                return (
                                                    <div className={`lg:inline-block lg:break-inside-avoid lg:w-full`} key={index}>
                                                        <a className={`inline-block hover:underline hover:underline-offset-2`} href={stream.link} key={index} title={`Listen to ${song.title} on ${stream.name}`} rel={`noopener noreferrer`} target={`_blank`}>
                                                            <span className={`text-sm`}>{stream.name}</span>
                                                        </a>
                                                    </div>
                                                )
                                                })}
                                            </div>
                                        </div>
                                        }

                                        {song.format &&
                                            <div className={`text-xs shrink-0 pb-2`}>
                                                <strong className={`uppercase font-medium font-sans tracking-widest pr-2 md:pr-5`}>Format: </strong> <span className={`text-sm`}>{song.format}</span>
                                            </div>
                                        }
                                
                                    </div>
                                }
                                </div>
                            </div>
                        }
                    </div>
                    </Fade>
                )
            })}
        </div>
    )
}